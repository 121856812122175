import React from 'react';

import SuccessLayout from '../components/layouts/SuccessLayout';
import Success from '../components/landing-pages/Success';

export default function success() {
  return (
    <>
      <SuccessLayout>
        <Success />
      </SuccessLayout>
    </>
  );
}
