import React, { useEffect } from 'react';
import {
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  Text,
  Box,
  Image,
} from '@chakra-ui/core';

export default function Success() {
  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.clear();
    };

    clearLocalStorage();
  }, []);

  return (
    <Flex w="100%" justifyContent="center">
      <Flex width={{ base: '100%', lg: 1024 }} direction="column" p={4}>
        <Flex as="header" mb={10}>
          <Box w={{ base: '250px', lg: '250px' }}>
            <Image
              src={'/img/EssentialInsuranceLogo.png'}
              alt="Essential insurance logo"
            />
          </Box>
        </Flex>

        <Flex
          bg="white"
          direction="column"
          w={{ base: '100%', md: '400px' }}
          margin="auto"
        >
          <Alert status="success">
            <AlertIcon />
            <AlertTitle mr={2}>Thank you</AlertTitle>
          </Alert>
          <Box mt={4}>
            <Text px={6} pb={6}>
              I’ll get back to you as soon as possible. If you do not hear from
              me soon enough, please feel free to contact me directly on
              <Text as="a" href="tel:01625466384" fontWeight="bold">
                <Text as="span">&nbsp;01625 466384</Text>
              </Text>
              .
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
